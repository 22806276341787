<template>
  <div>
    <el-card style="min-height: 45rem">
      <div class="flex-def" style="width: 100%">
        <el-row :gutter="20" style="width: 100%">
          <el-col :span="3">
            <el-input v-model="searchForm.order_no" placeholder="订单号"></el-input>
          </el-col>
          <el-col :span="2">
            <el-input v-model="searchForm.phone" placeholder="购买人手机号"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="searchForm.from_u_keyword" placeholder="推荐人手机号｜用户昵称"></el-input>
          </el-col>
          <el-col :span="3">
            <el-input v-model="searchForm.delivery_order_no" placeholder="发货单号"></el-input>
          </el-col>
          <el-col :span="3">
            <el-select v-model="searchForm.status">
              <el-option label="全部" :value="-1"></el-option>
              <el-option label="待付款" :value="0"></el-option>
              <el-option label="待发货" :value="1"></el-option>
              <el-option label="待收货" :value="2"></el-option>
              <el-option label="已完成" :value="3"></el-option>
            </el-select>
          </el-col>
          <el-col :span="9">
            <div class="flex-def flex-zBetween">
              <el-button-group>
                <el-button @click="searchGo" type="primary">搜索</el-button>
                <el-button @click="searchReset">重置</el-button>
              </el-button-group>
              <el-button-group>
                <el-popover
                    placement="left"
                    width="50"
                    trigger="click">
                  <div>
                    <el-checkbox-group v-model="fields">
                      <el-checkbox label="id">编号</el-checkbox>
                      <el-checkbox label="order_no">订单号</el-checkbox>
                      <el-checkbox label="name">收货人姓名</el-checkbox>
                      <el-checkbox label="phone">电话</el-checkbox>
                      <el-checkbox label="address">地址</el-checkbox>
                      <el-checkbox label="goods">商品</el-checkbox>
                      <el-checkbox label="remark">备注</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <el-button slot="reference" icon="el-icon-menu" type="primary"></el-button>
                </el-popover>
                <el-button @click="exportExcel">导出</el-button>
              </el-button-group>
              <el-button-group>
                <div class="flex-def">
                  <el-button @click="importExcelTpl">下载模版</el-button>
                  <el-upload
                      ref="uploader"
                      :action="importExcelAction"
                      :headers="{ytoken:$store.state.sys.account.token}"
                      name="file"
                      :show-file-list="false"
                      accept=".xlsx"
                      :on-success="success"
                      :on-error="error"
                      :limit="1"
                  >
                    <el-button type="primary">导入发货</el-button>
                  </el-upload>
                </div>
              </el-button-group>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-loading="loading" style="font-size: .8rem;width: 100%;min-height: 10rem;margin-top: 1rem">
        <div v-if="!list.length && !loading" class="y-desc flex-def flex-zCenter flex-cCenter"
             style="font-size: 2rem;height: 10rem">没有订单
        </div>
        <div class="order-item-box" v-for="(v,k) in list" :key="k">
          <div class="flex-def order-item-top" style="font-size: .8rem">
            <div class="order-item-top-item">订单ID:{{ v.id }}</div>
            <div class="order-item-top-item">订单编号:{{ v.order_no }}</div>
            <div class="order-item-top-item">支付单号:{{ v.transaction_id }}</div>
            <div class="order-item-top-item">下单时间:{{ v.created_at | date }}</div>
            <div class="order-item-top-item">支付时间:{{ v.pay_time | date }}</div>
          </div>
          <div class="flex-def order-item-center">
            <div class="order-item-center-item">
              <div class="flex-def order-item-center-goods" v-for="(value,key) in getGoodsListLog(v)" :key="key">
                <img :src="value.pic | tomedia" style="height: 5rem;width: 5rem;border-radius: .5rem">
                <div style="margin-left: .5rem">
                  <div>{{ value.title }}</div>
                  <div>{{ value.spec_title }}</div>
                </div>
              </div>
            </div>
            <div class="order-item-center-item flex-def flex-zTopBottom flex-zCenter">
              <div class="order-item-center-pay-info">下单人：({{ v.uid }}){{ v.user ? v.user.nickname : "" }}</div>
              <div class="order-item-center-pay-info">推荐人：({{ v.from_uid }}){{ v.from_user ? v.from_user.nickname : "" }}</div>
              <div class="order-item-center-pay-info">支付方式：{{ payTypeFormatter(v) }}</div>
            </div>
            <div class="order-item-center-item flex-def flex-zTopBottom flex-zCenter" style="font-size: .8rem">
              <div class="order-item-center-pay-info-box flex-def flex-zTopBottom flex-zCenter"
                   v-for="(value,key) in getGoodsListLog(v)" :key="key">
                <div class="order-item-center-pay-info">商品价格：{{ value.fee }}</div>
                <div class="order-item-center-pay-info">应付款：{{ value.fee_total }}</div>
                <div class="order-item-center-pay-info">数量：{{ value.num }}</div>
              </div>
            </div>
            <div class="order-item-center-item flex-def flex-zTopBottom flex-zCenter" style="font-size: .8rem">
              <div class="order-item-center-pay-info">商品小计：{{ v.fee }}</div>
              <div class="order-item-center-pay-info">运费：{{ v.delivery_fee }}</div>
              <div class="order-item-center-pay-info">抵扣：{{ v.cost_deduct_fee }}</div>
              <div class="order-item-center-pay-info">应付款：{{ v.real_pay }}</div>
              <div class="order-item-center-pay-info">订单商品数量：{{ v.cart.num }}</div>
            </div>
            <div class="order-item-center-item flex-def flex-zTopBottom flex-zCenter"
                 style="font-size: 1.6rem;color: #3dc0a5">
              {{ statusFormatter(v) }}
            </div>
            <div class="order-item-center-item flex-def flex-zTopBottom flex-zCenter flex-cCenter">
              <el-button @click="wantSend(v)" v-if="v.status==1" class="order-button">发货</el-button>
              <!--&lt;!&ndash;            <el-button @click="handPay(s.row.id)" v-if="s.row.status==0" size="mini">确认付款</el-button>&ndash;&gt;-->
              <el-button @click="handFinished(v.id)" v-if="v.status==2" class="order-button">确认收货</el-button>
            </div>
          </div>
          <div class="flex-def order-item-bottom">
            <div class="order-item-center-item flex-def flex-cCenter" style="width: 50%;position: relative">
              <span>{{ addressShow(v).name }}</span>
              <span style="margin-left: .5rem">{{ addressShow(v).phone }}</span>
              <span style="margin-left: .5rem">{{ addressShow(v).address }}</span>
              <el-button @click="$message.info('复制成功')"
                         v-clipboard:copy="`${addressShow(v).name}:${addressShow(v).phone}\n${addressShow(v).address}`"
                         class="order-button"
                         style="position:absolute;right: .5rem;">复制
              </el-button>
            </div>
            <div class="order-item-center-item" style="width: 25%">发货时间:{{ v.send_time | date }}</div>
            <div class="order-item-center-item" style="width: 10%">快递单号</div>
            <div class="order-item-center-item" style="width: 15%">{{ v.delivery_order_no }}</div>
          </div>
        </div>
      </div>
      <el-pagination v-if="!loading" style="padding: .5rem" background layout="total,prev, pager,next"
                     :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
      </el-pagination>
    </el-card>
    <el-dialog title="发货" :visible.sync="sendBox" @close="sendBoxClose">
      <div>
        <el-form label-width="70px">
          <el-form-item label="快递单号">
            <el-input v-model="sendForm.delivery_order_no"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="sendBox = false">取 消</el-button>
        <el-button type="primary" @click="sendSubmit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {PayTypeNum2Str} from "./tools";
import {getUniacid} from "@/api";

export default {
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      sendForm: {},
      searchForm: {
        order_no: "",
        phone: "",
        delivery_order_no: "",
        status: -1,
        from_u_keyword:""
      },
      sendBox: false,
      loading: false,
      fields:["id","order_no","name","phone","address","goods","remark"],
      importExcelAction:"",
    }
  },
  mounted() {
    this.load();
    this.importExcelAction =  this.axios.defaults.baseURL +  this.$api.shop.order.import2sendAction;
  },
  methods: {
    success(e){
      this.$refs.uploader.clearFiles();
      if (e.errno == 0){
        this.$message.success(`操作完成:${e.data.length}个订单发货成功`);
        this.load();
      }else {
        this.$message.error(e.message);
      }
    },
    error(e){
      console.log(e)
    },
    handleClose(done){
      done();
    },
    importExcelTpl(){
      location.href = location.origin + `/api/backend/shop/import-excel-tpl`;
    },
    exportExcel(){
      let raw = [];
      if(this.search2query())raw.push(this.search2query());
      if(this.fields2query())raw.push(this.fields2query());
      let query = raw.join("&");
      location.href = location.origin + `/api/backend/shop/export-excel?i=${getUniacid()}&${query}`;
    },
    search2query(){
      let arr = [];
      // if(this.searchForm.order_no) arr.push("order_no="+this.searchForm.order_no);
      // if(this.searchForm.phone) arr.push("phone="+this.searchForm.phone);
      // if(this.searchForm.delivery_order_no) arr.push("delivery_order_no="+this.searchForm.delivery_order_no);
      if(this.searchForm.status) arr.push("status="+this.searchForm.status);
      return arr.join("&")
    },
    fields2query(){
      let arr = [];
      this.fields.forEach(item=>{
        arr.push("list_field=" + item)
      })
      return arr.join("&")
    },
    payTypeFormatter(e) {
      return PayTypeNum2Str(e.pay_type);
    },
    searchGo() {
      this.page = 1;
      this.list.length = 0;
      this.load();
    },
    searchReset() {
      this.searchForm = {
        order_no: "",
        phone: "",
        delivery_order_no: "",
        status: -1
      }
      this.searchGo()
    },
    addressShow(item) {
      let v = JSON.parse(item.address_log);
      let ad = "";
      if(v.street_label){
        ad = v.province_label + v.city_label + v.district_label + v.street_label + v.detail
      }else {
        ad = v.province_label + v.city_label + v.area_label + v.street
      }
      return {
        name: v.name,
        phone: v.phone,
        address: ad
      }
    },
    sendSubmit() {
      this.$api.shop.order.send(this.sendForm).then(() => {
        this.$message.success("操作成功");
        this.sendBox = false;
        this.load();
      })
    },
    sendBoxClose() {
      this.sendForm = {}
      this.sendBox = false;
    },
    wantSend(item) {
      this.sendForm = {...item};
      this.sendBox = true;
    },
    handFinished(id) {
      this.$api.shop.order.handFinished({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    handPay(id) {
      this.$api.shop.order.handPay({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    statusFormatter({status}) {
      let str = "";
      switch (parseInt(status)) {
        case 0:
          str = "待付款";
          break;
        case 1:
          str = "待发货";
          break;
        case 2:
          str = "待收货";
          break;
        case 3:
          str = "已完成";
          break;
      }
      return str;
    },
    getGoodsListLog(item) {
      let arr = []
      item.cart.items.forEach(item => {
        let gLog = item.goods_log ? JSON.parse(item.goods_log) : {}
        let gOption = item.goods_option_log ? JSON.parse(item.goods_option_log) : {}
        arr.push({
          pic: gLog.pic ? gLog.pic : "",
          title: gLog.title ? gLog.title : "",
          spec_title: gOption.title ? gOption.title : "",
          fee: item.fee,
          num: item.num,
          fee_total: item.fee * item.num
        })
      })
      return arr;
    },
    load() {
      this.loading = true;
      this.$api.shop.order.search({
        page: this.page,
        delivery_order_no: this.searchForm.delivery_order_no,
        order_no: this.searchForm.order_no,
        phone: this.searchForm.phone,
        status: this.searchForm.status,
        from_u_keyword:this.searchForm.from_u_keyword,
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>
.order-button {
  background-color: #3dc0a5;
  color: #FFFFFF
}

.order-item-box {
  border: 1px solid #ededed;
  margin-bottom: .5rem
}

.order-item-top {
  border-bottom: 1px solid #ededed;
  background-color: #ededed;
}

.order-item-top-item {
  padding: .5rem;
  width: 22.5%;
  white-space: nowrap;
  font-weight: 600;
}

.order-item-top-item:first-child {
  width: 10%;
}
.order-item-top-item + .order-item-top-item{
  border-left: 1px solid #f8f8f8;
}

.order-item-center-item + .order-item-center-item {
  border-left: 1px solid #ededed;
}

.order-item-center {
  border-bottom: 1px solid #ededed;
}

.order-item-center-item {
  padding: .5rem;
  width: 10%;
  box-sizing: border-box;
}

.order-item-center-item:first-child {
  width: 30%;
}

.order-item-center-item:nth-child(2) {
  width: 20%;
}

.order-item-center-item:nth-child(4), .order-item-center-item:nth-child(6) {
  width: 15%;
}

.order-item-center-goods + .order-item-center-goods,
.order-item-center-pay-info-box + .order-item-center-pay-info-box {
  margin-top: .5rem;
  border-top: 1px solid #ededed;
  padding-top: .5rem;
}

.order-item-center-pay-info {
  margin-top: .2rem;
}

.order-item-center-pay-info + .order-item-center-pay-info {
  margin-top: .2rem;
}

.order-item-center-pay-info-box {
  height: 100%;
}
</style>